<template>
	<div class="login" ref="login">
		<v-main>
			<v-container fluid fill-height>
				<v-layout align-center justify-center>
					<v-flex xs12 sm8 md5>
						<v-card class="elevation-12">
							<v-toolbar dark flat class="" color="primary">
								<v-toolbar-title>
									{{ $t("login.authorization") }}
								</v-toolbar-title>
							</v-toolbar>
							<v-card-text class="px-6 pb-0">
								<v-alert
									v-if="alert"
									dense
									text
									:type="alert.type || 'warning'"
								>
									{{ alert.message || $t("login.unexpectedError") }}
								</v-alert>
								<v-alert
									v-if="roleProhibited"
									dense
									text
									type="error"
								>
									{{ roleProhibited }}
								</v-alert>

								<span class="subtitle-2 grey-accent-4--text font-weight-light">
									{{ $t("login.enterToAccount") }}
								</span>
								<v-form ref="form" v-model="valid" class="mt-2" lazy-validation>
									<v-row>
										<v-col cols="6" xl="3" lg="4" class="mt-2">
											<CountryCode 
												@change-mask="changeMask($event)"
											/>
										</v-col>
										<v-col cols="6" xl="9" lg="8">
											<v-text-field
												id="phone"
												ref="phone"
												v-model="phone"
												:placeholder="selected.code"
												persistent-placeholder
												v-mask="selected.mask"
												name="phone"
												class="phone-input"
												type="text"
												:label="$t('login.phoneNumber')"
												:rules="phoneRules"
												required
												@keyup.enter="handleLogin"
											>
											</v-text-field>
										</v-col>
									</v-row>
									
									<v-text-field
										ref="password"
										v-model="password"
										placeholder=" "
										persistent-placeholder
										id="password"
										name="password"
										:label="$t('login.password')"
										required
										counter
										prepend-icon="mdi-lock"
										:rules="passwordRules"
										:append-icon="showPassword ? 'mdi-eye' : 'mdi-eye-off'"
										:type="showPassword ? 'text' : 'password'"
										@click:append="showPassword = !showPassword"
										@keyup.enter="handleLogin"
									></v-text-field>
								</v-form>
							</v-card-text>
							<v-card-actions class="pa-6">
								<v-btn
									id="submit"
									:disabled="!valid"
									class="px-4 body-1 text-capitalize"
									color="primary"
									depressed
									:loading="logging"
									@click="handleLogin"
								>
									{{ $t("login.login") }}
								</v-btn>
								<v-spacer></v-spacer>
								<div class="d-flex flex-column">
									<router-link
										id="forgot_password"
										class="href mb-1 subtitle-2 font-weight-regular primary--text"
										:to="{
											path: '/forgotpassword',
											query: { phone: this.phone },
										}"
									>
										{{ $t("login.forgotPassword") }}
									</router-link>
									<router-link
										id="forgot_password"
										class="href mb-1 subtitle-2 font-weight-regular primary--text"
										:to="{
											path: '/agreements',
										}"
									>
										{{ $t("login.agreements") }}
									</router-link>
								</div>
							</v-card-actions>
						</v-card>
					</v-flex>
				</v-layout>
			</v-container>
		</v-main>
	</div>
</template>

<script>
import { mapGetters } from "vuex";
import CountryCode from "@/components/app/SelectCountryCode.vue";
export default {
	name: "Login",
	props: ['roleProhibited'],
	data() {
		return {
			alert: null,
			logging: false,
			mask: '+7-###-###-##-##',
			phone: "",
			password: "",
			showPassword: false,
			valid: true,
			selected: {
				code: '',
				mask: ''
			},
			phoneRules: [
				v => !!v || this.$t("login.errors.enterPhone"),
				v =>
					this.selected.code === '+7' ? ((v && v.length == 16) || this.$t("login.errors.incorrectPhoneFormat")) : (v && v.length > 14) || this.$t("login.errors.incorrectPhoneFormat"),
			],
			passwordRules: [v => !!v || this.$t("login.errors.enterPassword")],
		};
	},
	methods: {
		changeMask(event) {
			this.$nextTick(() => {
				this.phone = '';
			});
			this.selected = event
		},
		async handleLogin() {
			this.validate();
			try {
				this.logging = true;

				// +7(###)###-##-## format regex below
				// /\+7\([0-9]{3}\)[0-9]{3}\-[0-9]{2}\-[0-9]{2}/gm

				const phone = this.phone.replace(/[{()}]/g, "-");
				const data = {
					phone,
					password: this.password,
					application: 'company'
				};

				const res = await this.$store.dispatch("auth/login", data);
				console.log('res1', res)
				// await this.$store.dispatch("ksk/load_ksk_info");
				// this.$store.dispatch("ksk/load_houses");
				if (!res.user.role || res.user.role.name_system === 'ksk_employee' || res.user.role.name_system === 'user') {
					return this.alert = { type: "error", message: "Ваша роль не подходит для использования сайта. Пожалуйста, воспользуйтесь приложением." };
				}
				this.alert = { type: "success", message: res.message };
				this.$router.push("/");
			} catch (error) {
				console.log('err', error)	
				this.alert = { type: "error", message: error.message.response.data.message || "Проверьте правильность введенных Вами данных" };
			} finally {
				this.logging = false;
			}
		},
		validate() {
			this.$refs.form.validate();
		},
		reset() {
			this.$refs.form.reset();
		},
		resetValidation() {
			this.$refs.form.resetValidation();
		},
	},
	mounted() {},
	components: {
		CountryCode
	}
};
</script>

<style lang="scss" scoped>
.login {
	height: 100vh;
	width: 100vw;
	display: flex;
	justify-content: center;
	align-items: center;
	background: #f5f5f5;
}


</style>